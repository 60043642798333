import * as React from "react"
import PageLayout from "../../components/pageLayout";
import  {Table} from "antd";
import firebase from "firebase";
import { firebaseInit } from "../../firebaseConfig";

const  CatalogPage = () => {

    React.useEffect(()=> {

         firebaseInit(firebase);

         firebase.firestore().collection("products").get().then((querySnapshot)=>{

            let products  = querySnapshot.docs.map(doc => doc.data())            
            setProducts(products)
         })
    },[])
    const {Column} = Table
    
    const [products, setProducts ] = React.useState([]);

    const renderImage = (src) => {

      return (<div className="w-10"><img src={src}></img></div>)
    }

    const skuSorter = (a,b)=>a.sku-b.sku

    return (<PageLayout>

        <Table dataSource={products}>
          <Column title="" dataIndex="thumbail" render={renderImage}></Column>
          <Column title="Id" dataIndex="sku" sorter={skuSorter}></Column>
          <Column title="Nombre" dataIndex="title"></Column>
          <Column title="Marca" dataIndex="brand"></Column>
          <Column title="Proveedor" dataIndex="supplier" ></Column>
          <Column title="Modelo" dataIndex="model"></Column>          
          <Column title="Precio base" dataIndex="price"></Column>
          <Column title="Precio sugerido" dataIndex="retailPrice"></Column>
          <Column title="Departamento" dataIndex="department"></Column>
          <Column title="Categoría" dataIndex="category"></Column>
          <Column title="Subcategoría" dataIndex="subcategory"></Column>
        </Table>
    </PageLayout>)
} 

export default CatalogPage;